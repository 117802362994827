@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 20px 0;
  white-space: nowrap;
}

.logos:before,
.logos:after {
  position: absolute;
  width: 100px;
  height: 100px;
  content: '';
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

/* .logos:hover .logos-slide {
  animation-play-state: paused;
} */

.logos-slide {
  display: inline-block;
  animation: slide 30s infinite linear;
}

.logos-slide img {
  /* height: 50px; */
  margin: 0 60px;
  display: inline-block;
}
