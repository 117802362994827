body {
  margin: 0;
  font-family: 'CircularStd-Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.changing-text {
  position: relative;
}

.changing-text span {
  position: absolute;
  left: 0%;
  right: 0%;
  opacity: 0;
  /* animation: changeText 12s linear infinite; */
  animation-name: changeText;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: 'Calistoga';
  src: url('./fonts/CALISTOGA-REGULAR.TTF') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd-Light';
  src: url('./fonts/CircularStd/CircularStd-Light.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: url('./fonts/CircularStd/CircularStd-Medium.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url('./fonts/CircularStd/CircularStd-Bold.otf') format('opentype');
  font-style: normal;
}

@keyframes changeText {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  15% {
    opacity: 0;
    transform: translateY(0px);
  }
}

/*  delay the animation of each pan */

.changing-text span:nth-child(2) {
  animation-delay: 2s;
}

.changing-text span:nth-child(3) {
  animation-delay: 4s;
}

.changing-text span:nth-child(4) {
  animation-delay: 6s;
}

.changing-text span:nth-child(5) {
  animation-delay: 8s;
}

.changing-text span:nth-child(6) {
  animation-delay: 10s;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
